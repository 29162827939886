<template>
  <div class="iq-sidebar iq-sidebar-primary bg-sidebar-9">
    <div class="iq-sidebar-logo d-flex justify-content-between m-0 p-0">
      <!-- <h4 class="responsive-sidebar-sm">
        <b><i class=" fa-solid fa-folder header-logo pr-1 text-primary pt-4 ml-3"></i> APLICACIONES</b>
      </h4> -->
      <!-- <h4  class="responsive-sidebar-xl-md"> 
        <b><i class=" fa-solid fa-folder header-logo pr-1 text-primary pt-4 ml-3"></i> APLICACIONES</b>
      </h4> -->
      <input type="text" :value="seleccionarcategoria" hidden />
      <!-- <div class="iq-menu-bt-sidebar pt-2 mr-3  " @click="activov2()">
        <div class="iq-menu-bt align-self-center mt-0 pt-0">
          <div class="wrapper-menu mt-0 pt-0">
            <div class="main-circle mt-0 pt-0">
              <i style="color: var(--text-progress)" :class="'las ' + (colapsed == false ? 'la-arrow-circle-left' : 'la-arrow-circle-right')"></i>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="iq-menu-bt-sidebar pt-2 mr-3 responsive-sm">
                <div @click="activo()" class="iq-menu-bt align-self-center mt-0 pt-0">
                    <div class="wrapper-menu mt-0 pt-0">
                        <div class="main-circle mt-0 pt-0"><i style="color: var(--text-progress)"
                                :class="'las ' + (colapsed == false ? 'la-arrow-circle-left' : 'la-arrow-circle-right')"></i>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
      <div class="d-flex">
        <b class="text-nowrap"
          ><i
            class="fa-solid fa-folder header-logo pr-1 text-primary pt-4 ml-3"
          ></i>
          APLICACIONES</b
        >
        <div
          class="iq-menu-bt-sidebar pt-2 ml-auto pr-3 responsive-sidebar-sm"
          @click="activov2()"
        >
          <div class="iq-menu-bt align-self-center mt-0 pt-0">
            <div class="wrapper-menu mt-0 pt-0">
              <div class="main-circle mt-0 pt-0">
                <i
                  style="font-size: 1.5rem"
                  class="fa fa-bars text-primary"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav class="iq-sidebar-menu mt-3" id="17">
        <!-- 18 -->
        <CollapseMenu :items="dataRecibida" :open="true" />
      </nav>
    </div>
  </div>
</template>
<script>
import CollapseMenu from "@/components/menustyle/CollapseMenuV2";
import { core } from "@/config/pluginInit";
import Header from "@/JsonData/header9.json";
import { mapGetters } from "vuex";
import { eventBus } from "../../../../main";

export default {
  name: "SidebarStyle9",
  computed: {
    seleccionarcategoria() {
      console.log("ini seleccionarCategoria en panel izquierdo");
      let resulta = eventBus.$on("seleccionarcategoria", (data) => {
        this.dataRecibida = data;
        console.log("seleccionarCategoria en panel izq---> ", data);
      });
      return resulta;
    },
    ...mapGetters({
      logo: "logo",
    }),
  },
  components: {
    CollapseMenu,
    // activarMensaje() {
    //     if (this.items.length < 0)
    //         return true
    //     else
    //         return false

    //     }
  },
  beforeDestroy() {
    eventBus.$off("colapsando");
  },
  mounted() {
    try {
      eventBus.$emit(
        "seleccionarcategoria",
        JSON.parse(localStorage.getItem("menuseleccionado"))
      );

      // this.seleccionarcategoria =

      core.SmoothScrollbar();
      core.changesidebar();
      // eventBus.removeEventListener("colapsando")
      eventBus.$on("colapsando", (data) => {
        console.log("colapsando desde header en sidebar");
        this.activo(data);
      });
      eventBus.$on("colapsandov3", (data) => {
        console.log("colapsando desde header en sidebar");
        this.activov3(data);
      });
    } catch (error) {
      console.log("error", error);
    }
  },
  data() {
    return {
      homeurl: "",
      Header: Header,
      colapsed: true,
      dataRecibida: null,
    };
  },
  methods: {
    activov2() {
      console.log("activo-...");
      // let sidebarMain = document.body
      //sidebar-main
      console.log("------>", document.querySelector("body#sidebar-main"));
      if (document.body.classList.contains("sidebar-main")) {
        console.log('El body tiene la clase "sidebar-main".');
        //cuando remueve se hace visible
        document.body.classList.remove("sidebar-main");
        this.colapsed = true;
      } else {
        console.log('El body NO tiene la clase "sidebar-main".');
        this.colapsed = false;
        document.body.classList.add("sidebar-main");
      }
    },
    activo(data) {
      console.log("activo-...", data);
      let sidebarMain = document.body;
      if (data == false) {
        this.colapsed = true;
        sidebarMain.classList.remove("sidebar-main");
        return;
      }
      if (sidebarMain.classList.contains("sidebar-main")) {
        console.log('El body tiene la clase "sidebar-main".');
        sidebarMain.classList.remove("sidebar-main");
        this.colapsed = true;
      } else {
        console.log('El body NO tiene la clase "sidebar-main".');
        this.colapsed = false;
        sidebarMain.classList.add("sidebar-main");
      }
    },
    activov3(data) {
      console.log("activov3-...", data);
      let sidebarMain = document.body;
      if (data != false) {
        this.colapsed = true;
        sidebarMain.classList.remove("sidebar-main");
      } else {
        console.log('El body NO tiene la clase "sidebar-main".');
        this.colapsed = false;
        sidebarMain.classList.add("sidebar-main");
      }

      // this.colapsed = false;
      // sidebarMain.classList.add("sidebar-main");
    },
  },
};
</script>
<style>
.responsive-sidebar-sm {
  display: none !important;
}
.responsive-sidebar-xl-md {
  display: block;
}
@media (max-width: 1300px) {
  .responsive-sidebar-sm {
    display: block !important;
  }
  .responsive-sidebar-xl-md {
    display: none !important;
  }
}
</style>
