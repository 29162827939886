var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.items == [] || _vm.items == undefined)?_c('div',[_vm._m(0)]):_c('b-collapse',{staticClass:"mx-3",class:_vm.className + ' text-dark',staticStyle:{"word-wrap":"break-word !important"},attrs:{"tag":"ul","visible":_vm.open,"id":_vm.idName,"accordion":_vm.accordianName}},_vm._l((_vm.items.subGrupo),function(itemData){return _c('div',{key:itemData.id,staticClass:"mb-2"},[(itemData.nivel && itemData.nivel.includes(_vm.usuario.rol?.nivel))?_c('b-button',{class:'d-flex align-items-center ' + (_vm.visible ? null : 'collapsed' + ''),staticStyle:{"text-align":"left !important","font-size":"0.8rem !important","padding-right":"0","gap":"0.1rem"},attrs:{"aria-expanded":_vm.visible ? 'true' : 'false',"aria-controls":"collapse-4","block":"","variant":_vm.items.grupoSeleccionado == itemData.id ? 'primary' : 'light'},on:{"click":function($event){_vm.itemVisible = itemData.id}}},[_c('i',{class:_vm.itemVisible == itemData.id
              ? 'fas fa-caret-down'
              : 'fas fa-caret-right',staticStyle:{"font-size":"0.6rem"}}),_c('span',{staticClass:"text-truncate text-white pr-2"},[_vm._v(_vm._s(itemData.titulo))])]):_vm._e(),_vm._l((itemData.items),function(itemView){return _c('b-collapse',{key:itemView.id,attrs:{"id":'collapse-' + itemView.id,"visible":_vm.itemVisible === itemData.id}},[(itemView.nivel && itemView.nivel.includes(_vm.usuario.rol?.nivel))?_c('div',{staticClass:"ml-2 pointer mt-2",on:{"click":function($event){return _vm.abrirVista(itemView, itemData.id)}}},[_c('router-link',{staticClass:"pointer",attrs:{"to":itemView.ruta}},[(_vm.itemVisible == itemData.id)?[_c('p',{class:'p-2 m-0 rounded hover-menu-items apps ' +
                  (_vm.items.grupoSeleccionado == itemData.id &&
                  _vm.items.vistaSeleccionada == itemView.id
                    ? 'bg-active-item active-menu-item'
                    : 'bg-dark')},[_c('span',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","width":"97%","display":"block"}},[_vm._v(" "+_vm._s(itemView.titulo)+" ")])])]:_vm._e()],2)],1):_vm._e()])})],2)}),0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-5 px-2 text-center",staticStyle:{"min-width":"230px"}},[_c('div',[_c('i',{staticClass:"fa-solid fa-list mb-2",staticStyle:{"font-size":"5rem","color":"var(--muted-progress)"}})]),_c('h3',[_vm._v("¡Selecciona una categoria!")]),_c('p',[_vm._v("No tienes ninguna categoria seleccionada.")])])
}]

export { render, staticRenderFns }